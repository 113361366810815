import { Container, Grid } from "@mui/material";
import { useProductList } from "../../hooks/product";
import Page from "../../components/Page";
import GiftCard from "./GiftCard";
import { useEffect } from "react";
import { trackViewItemList } from "src/utils/trackingTags";

export default function Gift() {
  const giftBundles = useProductList(0, 100, "GiftBundle", true);

  const handleAddToCart = (giftBundle) => {
    // TODO: Implement add to cart; or put AddToCartButton in the modal
    console.log("Adding to cart:", giftBundle);
  };

  useEffect(() => {
    if (!giftBundles.isLoading && giftBundles.data) {
      trackViewItemList("Gift", "GiftPage", giftBundles.data.entries);
    }
  }, [giftBundles.data, giftBundles.isLoading]);

  return (
    <Page
      title="Gift Bundles"
      meta={{
        description:
          "Explore our curated gift bundles. Perfect for any occasion. Shop now.",
      }}
      breadcrumbs={[
        { label: "Home", route: "/" },
        {
          label: `Gifts`,
        },
      ]}
      loading={giftBundles.isLoading}
    >
      <Container>
        <Grid container spacing={2}>
          {giftBundles.data &&
            giftBundles.data.entries.map((giftBundle) => (
              <Grid item xs={12} sm={6} md={4} key={giftBundle.id}>
                <GiftCard
                  giftBundle={giftBundle}
                  onAddToCart={handleAddToCart}
                />
              </Grid>
            ))}
        </Grid>
      </Container>
    </Page>
  );
}
