import { Box, MenuItem, Stack, styled, useTheme } from "@mui/material";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import useResponsive from "src/hooks/useResponsive";
import MenuPopover from "src/components/MenuPopover";
import { Link as RouterLink } from "react-router-dom";
import MenuAccordion from "src/components/MenuAccordion";
import CreateObituaryButton from "src/components/CreateObituaryButton";

export const sharedLinkStyle = (props) => ({
  margin: props.theme.spacing(1.5),
  marginRight: props.theme.spacing(1.5),
  display: "flex",
  fontSize: "1rem",
  fontFamily: "karla",
  fontWeight: "unset",
  textDecoration: "none",
  color: "black",
  padding: 0,
  "&:hover": {
    color: props.theme.palette.primary.dark,
    cursor: "pointer",
  },
  "&.active": {
    color: props.theme.palette.primary.darker,
    borderBottomStyle: "solid",
    borderBottomWidth: "thin",
  },
});

export const sharedSubMenuLinkStyle = (props) => ({
  display: "block",
  lineHeight: "1.5714285714285714",
  fontSize: "0.875rem",
  fontFamily: "Inria Serif, Public Sans, sans-serif",
  fontWeight: 400,
  borderRadius: "6px",
  padding: "6px 16px",
  color: "#747F8A",
  textDecoration: "none",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "transparent",
    color: props.theme.palette.primary.dark,
    cursor: "pointer",
  },
  "&.active": {
    color: props.theme.palette.primary.darker,
  },
});

const NavLinkStyled = styled(NavLink)((props) => sharedLinkStyle(props));
const LinkToNowhere = styled("span")((props) => sharedLinkStyle(props));
const CreateObituaryButtonStyled = styled(CreateObituaryButton)((props) =>
  sharedSubMenuLinkStyle(props)
);

const StyledLink = ({ to, children, onClick, asCreateButton, ...other }) => {
  if (to) {
    return (
      <NavLinkStyled to={to} onClick={onClick} {...other}>
        {children}
      </NavLinkStyled>
    );
  } else {
    return (
      <LinkToNowhere onClick={onClick} {...other}>
        {children}
      </LinkToNowhere>
    );
  }
};

const hasActiveChildren = (childLinks, currentPath) => {
  if (!childLinks) return false;

  for (const childLink of childLinks) {
    if (
      currentPath
        .replace(/^\/+/g, "")
        .startsWith(childLink.route?.replace(/^\/+/g, ""))
    ) {
      return true;
    }

    if (hasActiveChildren(childLink.childLinks, currentPath)) {
      return true;
    }
  }
};

export default function MainMenuLink({
  text,
  to,
  childLinks = [],
  asCreateButton,
}) {
  const [openAnchor, setOpenAnchor] = useState(null);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [learnSubMenuOpen, setLearnSubMenuOpen] = useState(false);
  const isDesktop = useResponsive("up", "md");
  const { pathname } = useLocation();

  const isActiveParent = hasActiveChildren(childLinks, pathname);

  const handleClick = (e, label) => {
    e.preventDefault();
    if (label === "Learn") {
      setLearnSubMenuOpen(!learnSubMenuOpen);
    } else if (isDesktop) {
      if (openAnchor) {
        setOpenAnchor(null);
        setLearnSubMenuOpen(false);
        setSubMenuOpen(false);
      } else {
        setOpenAnchor(e.currentTarget);
        setLearnSubMenuOpen(false);
      }
    } else {
      setSubMenuOpen(!subMenuOpen);
      setLearnSubMenuOpen(false);
    }
  };

  const updateMenuState = () => {
    if (isActiveParent && !subMenuOpen) {
      setSubMenuOpen(true);
    }
    if (pathname === "/admin/topics" && !learnSubMenuOpen) {
      setLearnSubMenuOpen(true);
    }
    if (pathname === "/admin/articles" && !learnSubMenuOpen) {
      setLearnSubMenuOpen(true);
    }
  };

  const theme = useTheme();

  const renderChildLinks = (links) =>
    links.map(({ label, route, childLinks, asCreateButton }) =>
      childLinks && childLinks.length > 0 ? (
        <MenuItem
          key={label}
          onClick={(e) => {
            e.preventDefault();
            if (label === "Learn") {
              setLearnSubMenuOpen(!learnSubMenuOpen);
            } else if (asCreateButton) {
              setOpenAnchor(null);
              setSubMenuOpen(false);
              setLearnSubMenuOpen(false);
            } else {
              setSubMenuOpen(!subMenuOpen);
            }
          }}
          className={label === "Learn" && learnSubMenuOpen ? "active" : ""}
          sx={{
            ...sharedSubMenuLinkStyle({ theme }),
          }}
        >
          {label}
          {label === "Learn" && (
            <MenuAccordion open={learnSubMenuOpen}>
              {renderChildLinks(childLinks)}
            </MenuAccordion>
          )}
        </MenuItem>
      ) : asCreateButton ? (
        <CreateObituaryButtonStyled
          key={label}
          onClick={() => {
            setOpenAnchor(null);
            setSubMenuOpen(false);
            setLearnSubMenuOpen(false);
          }}
        >
          {label}
        </CreateObituaryButtonStyled>
      ) : (
        <MenuItem
          to={route}
          key={label}
          component={RouterLink}
          onClick={() => {
            setOpenAnchor(null);
            setSubMenuOpen(false);
            setLearnSubMenuOpen(false);
          }}
          className={
            pathname
              .replace(/^\/+/g, "")
              .startsWith(route?.replace(/^\/+/g, ""))
              ? "active"
              : ""
          }
          sx={{
            ...sharedSubMenuLinkStyle({ theme }),
          }}
        >
          {label}
        </MenuItem>
      )
    );

  if (childLinks.length > 0) {
    if (isDesktop) {
      updateMenuState();
      return (
        <>
          <StyledLink
            to={to}
            onClick={handleClick}
            className={isActiveParent ? "active" : ""}
          >
            {text}
          </StyledLink>
          <MenuPopover
            onClose={() => {
              setOpenAnchor(null);
              setSubMenuOpen(false);
              setLearnSubMenuOpen(false);
            }}
            open={!!openAnchor}
            anchorEl={openAnchor}
            sx={{
              p: 0,
              mt: 0.5,
              ml: 1.5,
              "& .MuiMenuItem-root": {
                typography: "body2",
                borderRadius: 0.75,
              },
            }}
          >
            {renderChildLinks(childLinks)}
          </MenuPopover>
        </>
      );
    } else {
      updateMenuState();
      return (
        <Box>
          <Stack alignItems="flex-start">
            <StyledLink
              to={to}
              onClick={(e) => handleClick(e, text)}
              className={subMenuOpen || isActiveParent ? "active" : ""}
            >
              {text}
            </StyledLink>
            {["Tributes", "Resources", "Shop", "Admin"].includes(text) && (
              <MenuAccordion
                open={subMenuOpen}
                sx={{
                  "& .MuiMenuItem-root": {
                    typography: "body2",
                    borderRadius: 0,
                    ml: 0,
                  },
                }}
              >
                {renderChildLinks(childLinks)}
              </MenuAccordion>
            )}
          </Stack>
        </Box>
      );
    }
  } else {
    return (
      <StyledLink to={to} asCreateButton={asCreateButton}>
        {text}
      </StyledLink>
    );
  }
}
