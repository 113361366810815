import { useQuery, useMutation, useQueryClient } from "react-query";
import api from "../services/api";
import { simpleGet, simplePost } from "../services/api_util";
import { ObjectToQueryString } from "src/utils/uri";

const getProductList = simpleGet(
  (params) => `/product?${ObjectToQueryString(params)}`
);
const getProductObituaryPlans = simpleGet(() => "/product/obituaryPlans");
const getProduct = simpleGet((productId) => `/product/${productId}`);
const addProductDraft = simplePost(
  (productCategory) => `/product/${productCategory}`
);
const updateProduct = async ({ productId, product }) => {
  const { data } = await api.put(`/product/${productId}`, product);
  data.id = productId;
  return data;
};

const updatePrimaryImage = async ({ productId, file }) => {
  const formData = new FormData();
  formData.append("file", file);
  const { data } = await api.postForm(
    `/product/${productId}/primaryImage`,
    formData
  );
  const result = {
    id: productId,
    primaryImageFileNamePrefix: data,
  };
  return result;
};

const addGalleryImage = async ({ productId, file }) => {
  const formData = new FormData();
  formData.append("file", file);
  const { data } = await api.postForm(
    `/product/${productId}/galleryImage`,
    formData
  );
  const result = {
    id: productId,
    imageGuid: data,
  };
  return result;
};

const deleteGalleryImage = async ({ productId, productImageId }) => {
  await api.delete(`/product/${productId}/galleryImage/${productImageId}`);
  return;
};

const PERSISTENT = {
  refetchOnWindowFocus: false,
  keepPreviousData: true,
};

export const useProductList = (
  page = 0,
  perPage,
  category,
  active,
  includesSubscription
) => {
  return useQuery(
    ["ProductList", { page, perPage, category, active, includesSubscription }],
    () =>
      getProductList({ page, perPage, category, active, includesSubscription }),
    PERSISTENT
  );
};

export const useProductObituaryPlans = () => {
  return useQuery(["ObituaryPlans"], getProductObituaryPlans, PERSISTENT);
};

export const useProduct = (productId) => {
  return useQuery(
    ["Product", { productId: parseInt(productId) }],
    () => getProduct(productId),
    PERSISTENT
  );
};

export const useAddProductDraft = () => {
  return useMutation(addProductDraft);
};

export const useUpdateProductPrimaryImage = () => {
  const queryClient = useQueryClient();

  return useMutation(updatePrimaryImage, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["ProductList"]);
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] === "Product" &&
            query.queryKey[1].productId === parseInt(data.id)
          );
        },
      });
    },
  });
};

export const useAddProductGalleryImage = () => {
  const queryClient = useQueryClient();

  return useMutation(addGalleryImage, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] === "Product" &&
            query.queryKey[1].productId === parseInt(data.id)
          );
        },
      });
    },
  });
};

export const useDeleteProductGalleryImage = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteGalleryImage, {
    onSuccess: (data, { productId }) => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] === "Product" &&
            query.queryKey[1].productId === parseInt(productId)
          );
        },
      });
    },
  });
};

export const useUpdateProduct = () => {
  const queryClient = useQueryClient();

  return useMutation(updateProduct, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["ProductList"]);
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] === "Product" &&
            query.queryKey[1].productId === parseInt(data.id)
          );
        },
      });
    },
  });
};
