import "src/components/invisible_quill.css";
import ReactQuill from "react-quill";
import { Box } from "@mui/material";
import { ImageFormats } from "@xeger/quill-image-formats";
import Quill from "quill";
import "react-quill/dist/quill.bubble.css";

Quill.register("modules/imageFormats", ImageFormats);

const QuillView = ({ value, sx }) => {
  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "indent",
    "header",
    "align",
    "script",
    "image",
    "link",
    "float",
    "width",
    "height",
    "size",
  ];

  return (
    <Box sx={{ listStylePosition: "inside", ...sx }}>
      <ReactQuill
        value={value}
        theme={"bubble"}
        readOnly={true}
        formats={formats}
      />
    </Box>
  );
};

export default QuillView;
